import { createContext, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleApiCall } from "../utils/apiCaller";

interface ChildrenProps {
  children?: JSX.Element[] | ReactElement;
}
export interface UserProps {
  name: string;
  email: string;
  token: string;
}
interface AuthContextData {
  signed: boolean;
  user: UserProps | undefined;
  Login(email?: string, senha?: string): Promise<UserProps>;
  Logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: ChildrenProps) {
  const [user, setUser] = useState<UserProps>();
  const navigate = useNavigate();
  function Logout() {
    localStorage.removeItem("user");
    setUser(undefined);
    navigate("/");
  }

  async function Login(email?: string, password?: string) {
    const response = await handleApiCall<UserProps>({
      method: 'POST',
      url: '/dashboard/login',
      payload: {
        email,
        password
      },
      logout: Logout
    })

    if (response?.ok) {
      const userSigned = response.data;
      setUser(userSigned);
      localStorage.setItem(
        "user",
        JSON.stringify({ ...userSigned, id: undefined })
      );

      return Promise.resolve(userSigned);
    }

    return Promise.reject(response);
  }

  useEffect(() => {
    const actualUser = localStorage.getItem("user");
    if (actualUser) {
      setUser(JSON.parse(actualUser));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, Login, Logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
