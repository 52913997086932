import {
  Button,
  Col,
  DatePicker,
  Empty,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { SearchProps } from "antd/es/input";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import ptBr from "dayjs/locale/pt-br";
import { Clock, Eye, Key, PaperPlaneTilt, Recycle, Trash } from "phosphor-react";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { errorAlert, loadingAlert, successAlert, warnAlert } from "../../components/Alerts";
import AuthContext from "../../contexts/auth";
import { PaginatedUsers } from "../../types/api";
import { handleApiCall } from "../../utils/apiCaller";
import { Attempt, AttemptsButton } from "./components/AttemptsButton";
import { useProducts } from "./hooks/useProducts";
import TextArea from "antd/es/input/TextArea";
dayjs.locale(ptBr);
export type BuyerProps = {
  provider: "eduzz" | "hotmart" | "pagarme";
  name: string;
  email: string;
  keys: Array<{
    label_name: string;
    created_at: string;
    secret: string;
    AccessAttempts?: Attempt[];
  }>;
  expires_at: Date | null;
  restricted: boolean;
};
export type ProductManagerCommentProps = {
  id: number;
  managerId: string;
  detail: string;
  created_at: string;
  updated_at: string;
};
interface UserProps {
  id: string;
  email: string;
  name: string;
  phone: string | null;
}

interface DataType extends UserProps {
  actions?: React.ReactNode;
  key: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Número",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Ações",
    dataIndex: "actions",
    key: "actions",
  },
];

export function ListaUsuarios() {
  const { findProducts, products } = useProducts();
  const [isRequestingAction, setIsRequestingAction] = useState(false);
  const { user, Logout } = useContext(AuthContext);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [isRequesting, setIsRequesting] = useState(false);
  const [users, setUsers] = useState<DataType[]>([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string | null>(
    null
  );
  const [moreActionsValue, setMoreActionsValue] = useState("");
  const [userVerify, setUserVerify] = useState<BuyerProps>();
  const [comments, setComments] = useState<ProductManagerCommentProps[]>([]);
  const [typeActionFocused, setTypeActionFocused] = useState<
    "Resend_72" | "Resend" | "More_Actions" | "Recreate_Access_Key"
  >("Resend_72");
  const [selectedIntegrationId, setSelectedIntegrationId] = useState("");
  const [actionTabSelected, setActionTabSelected] = useState<
    | "Resend_to_another"
    | "Unlock_email"
    | "Verify_user"
    | "Recreate_Access_Key"
    | "User_comment"
  >("Resend_to_another");

  useEffect(() => {
    if (products.length > 0) {
      setSelectedIntegrationId(products[0].integration_id);
    }
  }, [products]);

  const titleModal =
    typeActionFocused === "Resend_72"
      ? "Reenviar chaves até 72h"
      : typeActionFocused === "Resend"
      ? "Reenviar chaves de acesso"
      : typeActionFocused === "Recreate_Access_Key"
      ? "Recriar chaves de acesso"
      : "Ações";

  const contentConfirmModal = (email: string) =>
    typeActionFocused === "Resend_72"
      ? `Tem certeza que deseja reenviar as chaves até 72h para o email ${email}?`
      : typeActionFocused === "Resend"
      ? `Tem certeza que deseja reenviar as chaves de acesso para o email ${email}?`
      : actionTabSelected === "Resend_to_another"
      ? `Tem certeza que deseja reenviar as chaves de acesso do email ${email} para ${moreActionsValue}?`
      : actionTabSelected === "Unlock_email"
      ? `Tem certeza que deseja desbloquear o email ${email} até ${moreActionsValue}?`
      : typeActionFocused === "Recreate_Access_Key"
      ? `Tem certeza que deseja recriar as chaves de acesso do email ${email}?`
      : `Tem certeza que deseja verificar o usuário ${email}?`;

  const modalFn =
    typeActionFocused === "Resend_72"
      ? handleResendEmail_72h
      : typeActionFocused === "Resend"
      ? handleResendEmail
      : actionTabSelected === "Resend_to_another"
      ? handleResendToAnother
      : actionTabSelected === "Unlock_email"
      ? handleUnlockEmail
      : actionTabSelected === "Recreate_Access_Key"
      ? handleRecreateAccessKey
      : actionTabSelected === "User_comment"
      ? handleCreateComment
      : handleVerifyUser;

  const [isModalOpen, setIsModalOpen] = useState(false);
  // console.log(typeActionFocused, actionTabSelected, moreActionsValue);
  const isModalOkButtonDisabled =
    !selectedIntegrationId ||
    (typeActionFocused === "More_Actions" &&
      actionTabSelected !== "Resend_to_another" &&
      moreActionsValue.trim() === "") ||
    (typeActionFocused === "More_Actions" &&
      actionTabSelected === "User_comment" &&
      moreActionsValue.trim() === "") ||
    (typeActionFocused === "More_Actions" &&
      actionTabSelected === "Resend_to_another" &&
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        moreActionsValue
      ) === false);

  async function handleUnlockEmail() {
    const payload = {
      email: selectedUserEmail,
      date: moreActionsValue,
      provider: "pagarme", // TODO retirar o provider
      integration_id: selectedIntegrationId,
    };

    await handleApiCall<null>({
      url: "/dashboard/unlock",
      method: "POST",
      payload,
      token: user?.token,
      logout: Logout,
      
    });
  }

  async function handleVerifyUser(integrationId?: string) {
    if (!integrationId && selectedIntegrationId.trim() === "") {
      warnAlert({
        content: "Selecione uma assinatura",
        duration: 5,
      });
      return;
    } else if (integrationId && integrationId?.trim() === "") {
      warnAlert({
        content: "Selecione uma assinatura",
        duration: 5,
      });
      return;
    }
    const integrationd_id = integrationId ?? selectedIntegrationId;
    setIsRequestingAction(true);
    const response = await handleApiCall<BuyerProps>({
      url: `/dashboard/buyer?email=${selectedUserEmail}&integration_id=${integrationd_id}&provider=pagarme`,
      method: "GET",
      token: user?.token,
      logout: Logout,
    });
    if (response?.ok) {
      setIsRequestingAction(false);

      setUserVerify(response.data);
    }
  }

  async function hableGetUserComments(integrationId?: string) {
    if (!integrationId && selectedIntegrationId.trim() === "") {
      warnAlert({
        content: "Selecione uma assinatura",
        duration: 5,
      });
      return;
    } else if (integrationId && integrationId?.trim() === "") {
      warnAlert({
        content: "Selecione uma assinatura",
        duration: 5,
      });
      return;
    }
    const integrationd_id = integrationId ?? selectedIntegrationId;

    const productManagerId = products.find(
      (product) => product.integration_id === integrationd_id
    )?.id;
    setIsRequestingAction(true);
    const response = await handleApiCall<ProductManagerCommentProps[]>({
      url: `/dashboard/comments/${productManagerId}`,
      method: "GET",
      token: user?.token,
      logout: Logout,
      v2: true,
    });
    if (response?.ok) {
      setIsRequestingAction(false);
      setComments(response.data);
    }
  }

  async function handleCreateComment() {
    const payload = {
      detail: moreActionsValue,
      managerId: products.find(
        (product) => product.integration_id === selectedIntegrationId
      )?.id,
    };

    setIsRequestingAction(true);
    const response = await handleApiCall<ProductManagerCommentProps>({
      url: `/dashboard/comments/create`,
      method: "POST",
      token: user?.token,
      payload,
      logout: Logout,
      v2: true,
    });
    
    console.log(response)
    setIsRequestingAction(false);

    if (response?.ok) {
      const newComments = comments
      newComments.push(response.data)
      setComments(newComments);
      setMoreActionsValue("");
    }
  }

  async function handleDeleteComment(commentId: number) {
    
    loadingAlert({
      content: "Excluindo comentário...",
      key: "deleteComment",
    })
    const response = await handleApiCall<ProductManagerCommentProps[]>({
      url: `/dashboard/comments/${commentId}`,
      method: "DELETE",
      token: user?.token,
      logout: Logout,
      v2: true,
    });
    
   
    if (response?.ok) {
      successAlert({
        content: "Comentário excluído com sucesso",
        duration: 5,
        key: "deleteComment",
      });
      const filteredComments = comments?.filter((comment) => comment.id !== commentId);
      
      setComments(filteredComments);
      setMoreActionsValue("");
    }else{
      errorAlert({
        content: response?.message ?? "Erro ao excluir comentário",
        duration: 5,
        key: "deleteComment",
      });
    }
  }

  async function handleResendToAnother() {
    const payload = {
      from: selectedUserEmail,
      to: moreActionsValue,
      provider: "pagarme", // TODO retirar o provider
      integration_id: selectedIntegrationId,
    };

    await handleApiCall<null>({
      url: "/dashboard/resendToAnother",
      method: "POST",
      payload,
      token: user?.token,
      logout: Logout,
    });
  }

  async function handleResendEmail_72h() {
    const currentDate = dayjs();
    const addTreeDays = currentDate.add(3, "day");
    const payload = {
      email: selectedUserEmail,
      date: addTreeDays.toString(),
      provider: "pagarme", // TODO retirar o provider
      integration_id: selectedIntegrationId,
    };
    await handleApiCall<null>({
      url: "/dashboard/unlock",
      method: "POST",
      payload,
      token: user?.token,
      logout: Logout,
    });
  }

  async function handleResendEmail() {
    const payload = {
      email: selectedUserEmail,
      provider: "pagarme", // TODO retirar o provider
      integration_id: selectedIntegrationId,
    };
    await handleApiCall<null>({
      url: "/dashboard/resend",
      method: "POST",
      payload,
      token: user?.token,
      logout: Logout,
    });
  }

  async function handleRecreateAccessKey() {
    const payload = {
      email: selectedUserEmail,
      provider: "pagarme", // TODO retirar o provider
      integration_id: selectedIntegrationId,
    };
    await handleApiCall<null>({
      url: "/dashboard/recreateKey",
      method: "POST",
      payload,
      token: user?.token,
      logout: Logout,
    });
  }

  const convertIntoDataType = useCallback(
    (users: UserProps[] | undefined) => {
      return (
        users?.map((user) => {
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            phone: user.phone,
            key: user.id,
            actions: (
              <Flex gap={8}>
                <Tooltip title="Reenviar chaves até 72h">
                  <Button
                    title="Reenviar chaves até 72h"
                    shape="circle"
                    style={{ display: "grid", placeItems: "center" }}
                    icon={
                      <div style={{ position: "static" }}>
                        <PaperPlaneTilt size={16} />
                        <Clock
                          size={13}
                          style={{
                            position: "absolute",
                            top: -1.2,
                            borderRadius: "50%",
                            right: -2.5,
                            backgroundColor: "#fafafa",
                          }}
                        />
                      </div>
                    }
                    onClick={(e) => {
                      setSelectedUserEmail(user.email);
                      findProducts(user.id);
                      setTypeActionFocused("Resend_72");
                      setIsModalOpen(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Reenviar chaves de acesso">
                  <Button
                    title="Reenviar chaves de acesso"
                    shape="circle"
                    style={{ display: "grid", placeItems: "center" }}
                    icon={<PaperPlaneTilt size={16} />}
                    onClick={(e) => {
                      setSelectedUserEmail(user.email);
                      findProducts(user.id);
                      setTypeActionFocused("Resend");
                      setIsModalOpen(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Recriar chaves de acesso">
                  <Button
                    title="Recriar chaves de acesso"
                    shape="circle"
                    style={{ display: "grid", placeItems: "center" }}
                    icon={
                      <div style={{ position: "static" }}>
                        <Key size={16} />
                        <Recycle
                          size={13}
                          style={{
                            position: "absolute",
                            top: -1.2,
                            borderRadius: "50%",
                            right: -5.9,
                            backgroundColor: "#fafafa",
                          }}
                        />
                      </div>
                    }
                    onClick={(e) => {
                      setSelectedUserEmail(user.email);
                      findProducts(user.id);
                      setActionTabSelected("Recreate_Access_Key");
                      setTypeActionFocused("Recreate_Access_Key");
                      setIsModalOpen(true);
                    }}
                  />
                </Tooltip>

                <Button
                  title="Mais acões de usuário"
                  shape="round"
                  style={{ display: "grid", placeItems: "center" }}
                  onClick={(e) => {
                    setSelectedUserEmail(user.email);
                    findProducts(user.id);
                    setTypeActionFocused("More_Actions");
                    setIsModalOpen(true);
                    setMoreActionsValue("");
                    setActionTabSelected("Resend_to_another");
                    setUserVerify(undefined);
                    setComments([]);
                  }}
                >
                  Mais acôes
                </Button>
              </Flex>
            ),
          };
        }) ?? []
      );
    },
    [findProducts]
  );
  const getUsers = useCallback(async () => {
    setIsRequesting(true);

    const queryParams = new URLSearchParams({
      limit: String(pageSize),
      page: String(page),
      query: searchTerm,
    });

    const response = await handleApiCall<PaginatedUsers>({
      url: `/dashboard/users?${queryParams.toString()}`,
      method: "GET",
      token: user?.token,
      logout: Logout,
    });

    if (response) {
      if (!response.ok) {
        return errorAlert({
          content: response.message ?? "Erro ao buscar usuários",
          duration: 5,
        });
      }
      const {
        users,
        pagination: { total },
      } = response.data;

      const data: DataType[] = convertIntoDataType(users);

      setUsers(data);
      setTotal(total);
    }

    setIsRequesting(() => false);
  }, [Logout, convertIntoDataType, page, pageSize, searchTerm, user?.token]);

  const handleSearch: SearchProps["onSearch"] = async (value) => {
    setPage(1);
    setSearchTerm(value);
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const userBlocked = !!userVerify?.restricted;
  const accessExpired =
    userVerify?.expires_at && dayjs().isAfter(dayjs(userVerify?.expires_at));

  return (
    <Flex vertical>
      <Modal
        style={{
          minWidth: typeActionFocused === "More_Actions" ? 800 : "fit-content",
        }}
        title={titleModal}
        open={isModalOpen}
        confirmLoading={isRequestingAction}
        onOk={async () => {
          if (
            actionTabSelected === "Resend_to_another" &&
            typeActionFocused === "More_Actions"
          ) {
            Modal.confirm({
              okText: "Enviar",
              title: "Confirmar o envio",
              centered: true,
              width: "fit-content",
              onOk: async () => {
                setIsRequestingAction(true);
                return modalFn().then(() => {
                  setIsModalOpen(false);
                  setUserVerify(undefined);
                  setSelectedIntegrationId("");
                  setIsRequestingAction(false);
                });
              },
              content: contentConfirmModal(selectedUserEmail ?? ""),
              footer: (_, { OkBtn, CancelBtn }) => (
                <>
                  <CancelBtn />
                  <OkBtn />
                </>
              ),
            });
          } if(actionTabSelected ==='User_comment'){
            // setIsRequestingAction(true);
            return modalFn().then(() => {
              // setIsModalOpen(false);

              // setComments([]);
              
              // setIsRequestingAction(false);
            });
          }else {
            setIsRequestingAction(true);
            return modalFn().then(() => {
              setIsModalOpen(false);
              setUserVerify(undefined);
              setComments([]);
              setSelectedIntegrationId("");
              setIsRequestingAction(false);
            });
          }
        }}
        okButtonProps={{ disabled: isModalOkButtonDisabled }}
        onCancel={() => {
          setIsModalOpen(false);
          setUserVerify(undefined);
          setSelectedIntegrationId("");
          setComments([]);
        }}
        cancelText="Cancelar"
        okText="Enviar"
      >
        <Typography.Title level={5}>Selecione a assinatura</Typography.Title>
        <Select
          loading={isRequestingAction}
          showSearch
          style={{ width: "100%", height: "3rem" }}
          size="large"
          placeholder="Buscando assinaturas..."
          onChange={(e) => {
            setSelectedIntegrationId(e);
            if (actionTabSelected === "Verify_user") {
              handleVerifyUser(e);
            } else if (actionTabSelected === "User_comment") {
              hableGetUserComments(e);
            }
          }}
          value={
            isRequestingAction
              ? "Buscando assinaturas..."
              : selectedIntegrationId
          }
          optionFilterProp="children"
          filterOption={(input, option) =>
            String(option?.value).includes(input)
          }
          filterSort={(optionA, optionB) =>
            String(optionA?.label)
              .toLowerCase()
              .localeCompare(String(optionB?.label).toLowerCase())
          }
        >
          {products?.map((product) => {
            return (
              <Select.Option
                value={product.integration_id}
                key={product.id}
                label={product.plan_title}
              >
                <Flex vertical>
                  <Typography.Text>{product.plan_title}</Typography.Text>
                  <Typography.Text type="secondary">
                    {product.integration_id}
                  </Typography.Text>
                </Flex>
              </Select.Option>
            );
          })}
        </Select>

        {typeActionFocused === "More_Actions" &&
          (selectedIntegrationId ? (
            <>
              <Tabs
                onChange={(e) => {
                  setMoreActionsValue("");
                  setActionTabSelected(e as any);
                  if (e === "Verify_user") {
                    handleVerifyUser();
                  } else if (e === "User_comment") {
                    hableGetUserComments();
                  } else {
                    setUserVerify(undefined);
                  }
                }}
                activeKey={actionTabSelected}
              >
                <Tabs.TabPane
                  key="Resend_to_another"
                  tab="Reenviar para outro email"
                >
                  <Flex vertical gap={8}>
                    <Flex gap={0}>
                      <Tag
                        color="green"
                        style={{
                          minWidth: 50,
                          display: "grid",
                          placeItems: "center",
                          margin: 0,
                          borderRadius: ".5rem 0 0 .5rem",
                        }}
                      >
                        <b>DE</b>
                      </Tag>
                      <Input
                        style={{
                          borderRadius: "0 .5rem .5rem 0",
                          borderLeft: 0,
                        }}
                        size="large"
                        type="email"
                        disabled
                        value={selectedUserEmail ?? ""}
                        name="resendToAnotherEmail"
                        placeholder="Informe o email alternativo"
                      />
                    </Flex>

                    <Flex gap={0}>
                      <Tag
                        color="purple"
                        style={{
                          minWidth: 50,
                          display: "grid",
                          placeItems: "center",
                          margin: 0,
                          borderRadius: ".5rem 0 0 .5rem",
                        }}
                      >
                        <b>PARA</b>
                      </Tag>
                      <Input
                        style={{
                          borderRadius: "0 .5rem .5rem 0",
                          borderLeft: 0,
                        }}
                        size="large"
                        type="email"
                        value={moreActionsValue}
                        name="resendToAnotherEmail"
                        placeholder="Informe o email alternativo"
                        onChange={(e) => setMoreActionsValue(e.target.value)}
                      />
                    </Flex>
                  </Flex>
                </Tabs.TabPane>

                <Tabs.TabPane key="Unlock_email" tab="Desbloquear email">
                  <Flex gap={0}>
                    <Tag
                      color="green"
                      style={{
                        flex: 0,
                        display: "grid",
                        placeItems: "center",
                        margin: 0,
                        borderRadius: ".5rem 0 0 .5rem",
                      }}
                    >
                      <b>DESBLOQUEAR</b>
                    </Tag>
                    <Input
                      style={{
                        borderRadius: 0,
                        flex: 1,
                        borderInline: 0,
                      }}
                      size="large"
                      type="email"
                      disabled
                      value={selectedUserEmail ?? ""}
                      name="resendToAnotherEmail"
                      placeholder="Informe o email alternativo"
                    />
                    <Tag
                      color="purple"
                      style={{
                        minWidth: 50,
                        display: "grid",
                        placeItems: "center",
                        margin: 0,
                        borderRadius: 0,
                      }}
                    >
                      <b>ATÉ</b>
                    </Tag>
                    <DatePicker
                      style={{ borderRadius: "0 .5rem .5rem 0", borderLeft: 0 }}
                      onChange={(date) => {
                        setMoreActionsValue(date?.format("YYYY-MM-DD") ?? "");
                      }}
                      value={
                        moreActionsValue ? dayjs(moreActionsValue) : undefined
                      }
                      placeholder="Desbloquear até..."
                      size="large"
                    />
                  </Flex>
                </Tabs.TabPane>

                <Tabs.TabPane key="Verify_user" tab="Verificar usuário">
                  {/* Verificar as informacões do usuário após ser clicado nessa tab */}
                  <Flex vertical>
                    {userVerify ? (
                      <Flex vertical>
                        <Flex gap={4} justify="space-between">
                          <Flex vertical gap={1}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                              {userVerify?.name}
                            </Typography.Title>
                            <Typography.Text type="secondary">
                              {userVerify?.email}
                            </Typography.Text>
                          </Flex>
                          <Flex vertical align="end" gap={1}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                              {userVerify.provider}
                            </Typography.Title>
                            <Tooltip title="Status do usuário">
                              <Tag
                                color={userBlocked ? "red" : "green"}
                                style={{ margin: 0 }}
                              >
                                {userBlocked ? "Bloqueado" : "Desbloqueado"}
                              </Tag>
                            </Tooltip>
                          </Flex>
                        </Flex>
                        <Typography.Text>
                          {userVerify.expires_at ? (
                            <>
                              <b style={{ marginRight: 5 }}>
                                Data de expiração:
                              </b>

                              {dayjs(userVerify.expires_at).format(
                                "YYYY/MM/DD [às] HH:mm"
                              )}
                            </>
                          ) : (
                            <b>Assinatura Recorrente</b>
                          )}
                        </Typography.Text>
                        <Tooltip title="Status da assinatura">
                          <Tag
                            color={accessExpired ? "orange" : "purple"}
                            style={{
                              marginInline: 0,
                              marginBlock: "1.2rem",
                              paddingBlock: ".5rem",

                              textAlign: "center",
                            }}
                          >
                            {accessExpired
                              ? "Acesso expirado"
                              : "Acesso válido"}
                          </Tag>
                        </Tooltip>

                        <Typography.Title level={5}>
                          Chaves de acesso
                        </Typography.Title>
                        <List
                          pagination={{
                            align: "end",
                            pageSize: 3,
                            total: userVerify?.keys?.length ?? 0,
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} de ${total} chaves`,
                          }}
                          itemLayout="horizontal"
                          dataSource={userVerify?.keys}
                          locale={{
                            emptyText: (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={"Sem chaves"}
                                imageStyle={{
                                  filter:
                                    "drop-shadow(0 0 1px hsl(240deg 100% 80%))",
                                }}
                              />
                            ),
                          }}
                          renderItem={(item) => {
                            return (
                              <List.Item
                                style={{
                                  padding: "1rem",
                                  marginBottom: "0.5rem",
                                  borderRadius: "1rem 1rem 0 0",
                                  borderBottom: "none",
                                  background:
                                    "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(104,104,176,0.1) 100%)",
                                }}
                              >
                                <List.Item.Meta
                                  title={
                                    <Flex justify="space-between">
                                      <b>{item.label_name}</b>{" "}
                                      <AttemptsButton
                                        attempts={item.AccessAttempts ?? []}
                                      />
                                    </Flex>
                                  }
                                  description={
                                    <Flex justify="space-between" wrap="wrap">
                                      <p>{item.secret}</p>
                                      <time
                                        style={{
                                          textAlign: "end",
                                          width: "100%",
                                        }}
                                        title={dayjs(item.created_at).format(
                                          "DD, MMM [de] YYYY [às] HH:mm"
                                        )}
                                      >
                                        Criado em:{" "}
                                        {dayjs(item.created_at).format(
                                          "DD, MMM [de] YYYY [às] HH:mm"
                                        )}
                                      </time>
                                    </Flex>
                                  }
                                />
                              </List.Item>
                            );
                          }}
                        />
                      </Flex>
                    ) : isRequestingAction ? (
                      <Spin />
                    ) : (
                      <Empty description={false} />
                    )}
                  </Flex>
                </Tabs.TabPane>

                <Tabs.TabPane key="User_comment" tab="Comentários">
                  {isRequestingAction && moreActionsValue.trim() === "" ? (
                    <Spin />
                  ) : (
                    <Row justify={"space-around"} gutter={12}>
                      
                      <Col span={12}>
                        
                        <TextArea
                          showCount
                          maxLength={1000}
                          value={moreActionsValue}
                          onChange={(e) => setMoreActionsValue(e.target.value)}
                          placeholder="Comente aqui"
                          style={{ height: "100%", resize: "none", width: "100%" }}
                        />
                      </Col>
                      <Col span={12}>
                      <List
                        bordered
                          header={
                            <Typography.Title level={5}>
                              Comentários
                            </Typography.Title>
                          }
                          locale={{
                            emptyText: (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={"Nenhum comentário encontrado."}
                                imageStyle={{
                                  filter: "drop-shadow(0 0 1px hsl(240deg 100% 80%))",
                                }}
                              />
                            ),
                          }}
                          pagination={{
                            align: "end",
                            pageSize: 3,
                            size: "small",
                            total: comments?.length ?? 0,
                            simple: true,
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} de ${total} comentários`,
                          }}
                          dataSource={comments}

                          size="small"
                          renderItem={(item) => (
                            <Flex style={{padding: "5px 10px", borderTop: "1px solid #cecece"}} vertical align="end" >
                                <Flex gap={4} style={{width:"100%"}}>
                                  <Flex vertical gap={4} style={{width:"100%", maskImage:"linear-gradient(180deg, #000 60%, transparent)"}} align="start">
                                  <span style={{fontWeight:400, backgroundColor:"#f0f0f0", borderRadius:"5px", padding: "0px 5px"}}>Criado em: {dayjs(item.created_at).format(
                                      "YYYY/MM/DD HH:mm"
                                    )}</span>

                                  <Typography.Text style={{fontWeight:200, margin: "0 5px"}}>{item.detail.slice(0,80)}</Typography.Text>
                                  
                                  </Flex>
                                  <Flex vertical gap={8}>
                                  <Button
                                    title="Excluir comentário"
                                    shape="circle"
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                    }}
                                    
                                    icon={
                                      <div style={{ position: "static" }}>
                                        <Trash size={16} />
                                      </div>
                                    }
                                    onClick={(e) => handleDeleteComment(item.id)}
                                  />
                                  <Button
                                    title="Ver comentário"
                                    shape="circle"
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                    }}
                                    
                                    icon={
                                      <div style={{ position: "static" }}>
                                        <Eye size={16} />
                                      </div>
                                    }
                                    onClick={(e) => {
                                      setMoreActionsValue(item.detail);
                                    }}
                                  />
                                  </Flex>
                                  
                                </Flex>
                                {/* <span style={{fontWeight:200, backgroundColor:"#f0f0f0", borderRadius:"5px", padding: "0px 5px"}}>{dayjs(item.updated_at).format(
                                      "YYYY/MM/DD HH:mm"
                                    )}</span> */}

                            </Flex>
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </>
          ) : (
            <>
              <Flex vertical gap={8} style={{ marginTop: 8 }}>
                <Flex gap={8}>
                  <Skeleton.Button
                    active={true}
                    shape="default"
                    size="default"
                    block={true}
                  />
                  <Skeleton.Button
                    active={true}
                    shape="default"
                    size="default"
                    block={true}
                  />
                  <Skeleton.Button
                    active={true}
                    shape="default"
                    size="default"
                    block={true}
                  />
                </Flex>
                <Skeleton.Input
                  active={true}
                  size="default"
                  block={true}
                  style={{ width: "80%" }}
                />
                <Skeleton.Input
                  active={true}
                  size="default"
                  block={true}
                  style={{ width: "80%" }}
                />
              </Flex>
            </>
          ))}
      </Modal>

      <Typography.Title level={2}>Lista de usuários</Typography.Title>
      <Form>
        <Input.Search
          size="large"
          type="text"
          name="searchUserByEmail"
          allowClear
          placeholder="Pesquise por: email, chave de acesso, nome ou telefone"
          onSearch={handleSearch}
        />
      </Form>
      <br />
      <Table
        columns={columns}
        loading={isRequesting}
        dataSource={users}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"Nenhum usuário encontrado. Tente alterar a busca."}
              imageStyle={{
                filter: "drop-shadow(0 0 1px hsl(240deg 100% 80%))",
              }}
            />
          ),
        }}
        pagination={{
          current: page,
          pageSize,
          showSizeChanger: true,
          total,
          onChange: (page, pageSize) => {
            setPageSize((state) => {
              if (state !== pageSize) {
                setPage(1);
              } else {
                setPage(page);
              }
              return pageSize;
            });
          },
        }}
      />
    </Flex>
  );
}
