import { ArrowUUpLeft } from "phosphor-react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, ReturnButton } from "./styles";
function UpdateUser() {
    const params = useParams()
    const navigate = useNavigate();
    function handleGoBack(){
        navigate(-1)
    }

    return ( 
        <Container>
            <ReturnButton><ArrowUUpLeft size={25} onClick={handleGoBack} /><h1>Alterar usuário</h1></ReturnButton>
            <p>{params.id}</p>
        </Container>
     );
}

export default UpdateUser;