import { useCallback, useContext, useState } from "react";
import { errorAlert } from "../../../components/Alerts";
import AuthContext from "../../../contexts/auth";
import { handleApiCall } from "../../../utils/apiCaller";

export interface Product {
  id: string;
  integration_id: string;
  restricted: boolean;
  key_limit: number;
  plan_title: string;
  created_at: string;
  marketplace: string;
  expires_at: string;
  plan_id: string;
}

export const useProducts = () => {
  const [isRequestingAction, setIsRequestingAction] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const { user, Logout } = useContext(AuthContext);

  const findProducts = useCallback(
    async (userId: string) => {
      setIsRequestingAction(true);
      const response = await handleApiCall<Product[]>({
        method: 'GET',
        url:`/dashboard/owner-product-managers?id=${userId}`,
        token: user?.token,
        logout: Logout
      })

      setIsRequestingAction(false);
        if (!response?.ok) {
          errorAlert({
            content: "Erro ao buscar produtos",
            duration: 5,
          });

          return
        }

        const productsData: Product[] = response.data ?? [];

        setProducts(productsData);
      // }
    },
    [Logout, user?.token]
  );

  return {
    products,
    findProducts,
    loadingProducts: isRequestingAction
  }
}