import { AxiosError, AxiosResponse } from "axios";

import api from "../api";
import { errorAlert, successAlert } from "../components/Alerts";
import { ApiResponse } from "../types/api";
import apiv2 from "../apiv2";

interface ApiCallProps {
  url: string;
  method: "POST" | "GET" | "PATCH" |"PUT" | "DELETE";
  payload?: any;
  token?: string;
  logout: () => void;
  v2?: boolean;
}

export async function handleApiCall<T>({
  method,
  url,
  payload,
  token,
  logout,
  v2=false
}: ApiCallProps): Promise<ApiResponse<T> | undefined> {
  if (!method) return;

  //<ApiResponse<null>>
  let response: AxiosResponse<ApiResponse<T>>
  try {
    if(v2){
      response = await apiv2({
        method,
        url,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }else{
      response = await api({
        method,
        url,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    



    if (!response.data.ok) {
      const message = response.data.message
      if (message.includes('Zod Errors') && response.data.data) {
        Object.values(response.data.data).forEach((value: unknown) => {
          errorAlert({
            content: `${value}`,
            duration: 5,
            key: String(value)
          });
        });

        return
      }
      errorAlert({
        content: response.data.message ?? "Erro desconhecido",
        duration: 5,
      });

      return
    } else {
      successAlert({
        content: response.data.message ?? "Sucesso",
        duration: 5,
      });
      return response.data;
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        logout();
        errorAlert({
          content: err.response?.data.message ?? "Erro desconhecido",
          duration: 5,
        });

        return
      } else if (err.response?.status === 400) {
        const message = err.response.data.message
        if (message.includes('Zod Errors')) {
          Object.values(err.response?.data.data).forEach((value: unknown) => {
            errorAlert({
              content: `${value}`,
              duration: 5,
              key: String(value)
            });
          });

          return
        }
        if (Array.isArray(message)) {
          Object.values(err.response?.data.message).forEach((value: unknown) => {
            errorAlert({
              content: `${value}`,
              duration: 5,
              key: String(value)
            });
          });

          return
        } else {
          errorAlert({
            content: message,
            duration: 5,
          });

          return
        }
      } else {
        errorAlert({
          content: err.response?.data.message ?? "Erro desconhecido",
          duration: 5,
        });

        return
      }
    }
  }
}
