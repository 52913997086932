import { BrowserRouter } from "react-router-dom";
import Router from "./components/Router";
import { SideBar } from "./components/SideBar";
import { AuthProvider } from "./contexts/auth";
import { GlobalStyle } from "./styles/global";
import { ConfigProvider } from "antd";
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ConfigProvider
          theme={{
            components: {
              Form: {
                colorError: "#fe6c6c",
              },
              Input: {
                colorError: "#fe6c6c",
              },
            },
          }}
        >
          <SideBar>
            <Router />
          </SideBar>
        </ConfigProvider>
      </AuthProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}

export default App;
