import axios from "axios";


const apiv2 = axios.create({
  baseURL: process.env.REACT_APP_LINK_API_V2,

});


export default apiv2;
