import type { MenuProps } from "antd";
import { Flex, Layout, Menu, Typography } from "antd";
import { SignOut } from "phosphor-react";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/LOGOZAPVOICE.webp";
import AuthContext from "../../contexts/auth";
import Login from "../../pages/Login";
import { routeArr } from "../Router";
import "./style.css";
const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

interface ChildrenProps {
  children?: JSX.Element[] | ReactElement;
}

export function SideBar({ children }: ChildrenProps) {
  const [items, setItems] = useState<MenuItem[]>([]);
  const { Logout, user, signed } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    let newArr: MenuItem[] = [];
    for (let route of routeArr) {
      newArr.push(
        getItem(<Link to={route.to}>{route?.name}</Link>, route.to, route.icon)
      );
    }
    setItems(newArr);
  }, []);
  return (
    <>
      {signed ? (
        <Layout>
          <Sider style={{ background: "#6868b0" }}>
            <img src={logo} className="logo" alt="Logo" title="Logo ZapVoice" />
            <Menu
              theme="dark"
              style={{ fontSize: "1.2rem" }}
              defaultSelectedKeys={[location.pathname]}
              mode="inline"
              items={items}
            />
          </Sider>
          <Layout
            className="site-layout"
            style={{ backgroundColor: "#6868b0" }}
          >
            <Header className="site-layout-background header">
              <Flex vertical>
                <Typography.Text
                  style={{
                    textAlign: "end",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  {user?.name}
                </Typography.Text>
                <Typography.Text
                  type="secondary"
                  style={{ textAlign: "end", color: "white" }}
                >
                  {user?.email}
                </Typography.Text>
              </Flex>
              <SignOut
                size={25}
                onClick={() => {
                  Logout();
                  document.location.reload();
                }}
              />
            </Header>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                borderTopLeftRadius: 10,
                minHeight: "calc(100vh - 130px)",
              }}
            >
              <Content style={{ margin: "20px 16px" }}>
                <div
                  className="site-layout-background"
                  style={{
                    padding: 24,
                    minHeight: 360,
                    background:
                      "linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(104,104,176,0.1) 100%)",
                    borderRadius: ".5rem",
                  }}
                >
                  {children}
                </div>
              </Content>
            </div>

            <Footer style={{ textAlign: "center" }}>Zap Voice DashBoard</Footer>
          </Layout>
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
}
