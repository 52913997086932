import { Button, Flex, Form, Input, Typography } from "antd";
import { useContext, useState } from "react";
import { errorAlert, warnAlert } from "../../components/Alerts";
import AuthContext from "../../contexts/auth";
import { handleApiCall } from "../../utils/apiCaller";

function Home() {
  const { user, Logout, Login } = useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleUpdatePassword(values: any) {
    if (newPassword.trim() !== confirmPassword.trim()) {
      return warnAlert({
        content: "As senhas não coincidem",
        duration: 5,
      });
    }

    const data = {
      oldPassword: currentPassword,
      password: newPassword,
    };
    setIsLoading(true);

    try {
      const response = await handleApiCall({
        method: "PATCH",
        url: "/dashboard/update-password",
        payload: data,
        logout: () => {},
        token: user?.token,
      });

      if (response?.ok) {
        form.resetFields();

        try {
          await Login(user?.email, newPassword);
        } catch (e) {
          errorAlert({
            content:
              "Não foi possível atualizar sua sessão, realize o login novamente",
            duration: 5,
            key: "password",
          });

          return  Logout();
        }
      } else {
        errorAlert({
          content: response?.message ?? "Não foi possível alterar a senha",
          duration: 5,
          key: "password",
        });

        return
      }
    } catch (error) {
      errorAlert({
        content: String(error) ?? "Não foi possível alterar a senha2",
        duration: 5,
        key: "password",
      });

      return
    } finally {
      setIsLoading(false);
    }

  }

  return (
    <Flex vertical gap={8}>
      <Typography.Title level={2}>Configurações</Typography.Title>

      <Flex vertical>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Alteração de senha
        </Typography.Title>
        <Form
          onFinish={handleUpdatePassword}
          style={{ width: 300, marginTop: 10 }}
          layout="vertical"
          form={form}
        >
          <Form.Item
            style={{ marginBottom: "1rem" }}
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Por favor informe sua senha atual!",
              },
            ]}
            label="Senha atual"
          >
            <Input.Password
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "1rem" }}
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Por favor informe sua nova senha!",
              },
            ]}
            label="Nova senha"
          >
            <Input.Password
              name="newPasswod"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "1rem" }}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Por favor confirme sua nova senha!",
              },
            ]}
            label="Confirmação de senha"
          >
            <Input.Password
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>

          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ marginTop: 12, width: "100%" }}
          >
            Salvar
          </Button>
        </Form>
      </Flex>
    </Flex>
  );
}

export default Home;
