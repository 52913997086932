import styled from "styled-components";

export const Container = styled.div`

`

export const ReturnButton = styled.span`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    svg{
        width: 50px;
        height: 50px;
        padding: 12px;
        background-color: #6868b0;
        border-radius: 50%;
        cursor: pointer;
        color: #fafafa;
    }

    h1{
        margin: 0;
    }
`