import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/LOGOZAPVOICE.webp";
import { warnAlert } from "../../components/Alerts";
import AuthContext from "../../contexts/auth";

import { Button, Flex, Form, Image, Input } from "antd";
import { Lock, User } from "phosphor-react";
function Login() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function handleLogin(values: any) {
    setLoading(true);

    context
      .Login(values.email, values.password)
      .then((resp) => {
        navigate("/");
      })
      .catch((err) => {
        if (err) {
          warnAlert({
            content: err.response?.data ?? err.message ?? err,
            style: {
              marginTop: "0vh",
              marginRight: "0",
            },
          });
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <Flex
      justify="center"
      align="center"
      vertical
      style={{
        height: "100vh",
        // background: "rgb(104,104,176)",
        background:
          "radial-gradient(circle, rgba(104,104,176,1) 0%, rgba(0,188,242,1) 100%)",
      }}
    >
      <Flex
        align="stretch"
        vertical
        style={{
          width: "350px",
          padding: "20px",
          background: "rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(6px)",
          WebkitBackdropFilter: "blur(6px)",
          border: "1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <Flex justify="center">
          <Image src={logo} alt="Logo" width={250} preview={false} />
        </Flex>

        <Form name="normal_login" onFinish={handleLogin}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Por favor informe seu email!" },
            ]}
          >
            <Input size="large" prefix={<User />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Por favor informe sua senha!" },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<Lock />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  );
}

export default Login;
