import {
  Button,
  Empty,
  Flex,
  List,
  Modal,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { ShieldWarning } from "phosphor-react";
import { useState } from "react";
import { formatPhone } from "../../../../utils/phoneFormatter";

export interface Attempt {
  phone: string;
  times: number;
  updated_at: string;
}

interface AttemptsButtonProps {
  attempts: Attempt[];
}
export function AttemptsButton({ attempts }: AttemptsButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const totalAccessAttempts = attempts.length;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Tentativas de acesso"
        open={isModalOpen}
        centered
        onCancel={closeModal}
        onOk={closeModal}
        width={343}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <List
          bordered
          pagination={{
            position: "bottom",
            align: "center",
            pageSize: 5,
            total: attempts.length,
            showLessItems: true,
            simple: true,
          }}
          dataSource={attempts.sort((a, b) => b.times - a.times)}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Sem tentativas'}
                imageStyle={{filter: 'drop-shadow(0 0 1px hsl(240deg 100% 80%))'}}
              />
            )
          }}
          renderItem={(attempt) => (
            <List.Item
              style={{
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Flex>
                <Tag color="volcano">{attempt.times}</Tag>
                <span>{formatPhone(attempt.phone)}</span>
              </Flex>
              <Typography.Text type="secondary">
                Última tentativa em:{" "}
                {dayjs(attempt.updated_at).format("DD/MM/YYYY HH:mm")}
              </Typography.Text>
            </List.Item>
          )}
        />
      </Modal>

      <Tooltip title="Tentativas de acesso">
        <Button
          shape="round"
          danger={totalAccessAttempts > 0}
          type="primary"
          icon={<ShieldWarning size={20} />}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
          onClick={showModal}
        >
          {totalAccessAttempts}
        </Button>
      </Tooltip>
    </>
  );
}
