import { message } from "antd";

interface AlertType {
  content: string,
  className?: string,
  key?: string
  style?: {
    marginTop?: string,
    marginRight?: string
  },
  duration?: number
}
export const loadingAlert = (props: AlertType)=>{
  message.loading({
    content: props.content,
    key: props.key ?? 'loading',
    style: {
      position: "relative",
      marginTop: props.style?.marginTop,
      right: props.style?.marginRight,
      float: "right"
    },
    duration: props.duration || 3
  });
}
export const errorAlert = (props: AlertType)=>{
  message.error({
    content: props.content,
    key: props.key ?? 'error',
    style: {
      position: "relative",
      marginTop: props.style?.marginTop,
      right: props.style?.marginRight,
      float: "right"
    },
    duration: props.duration || 3
  });
}

export const successAlert = (props: AlertType) => {

    message.success({
      content: props.content,
      key: props.key ?? 'success',
      className: props.className,
      style: {
        position: "relative",
        marginTop: props.style?.marginTop,
        right: props.style?.marginRight,
        float: "right"
      },
      duration: props.duration || 3
    });

};

export const warnAlert = (props: AlertType) => {

  message.warning({
    content: props.content,
    key: props.key ?? 'warn',
    className: props.className,
    style: {
      position: "relative",
      marginTop: props.style?.marginTop,
      right: props.style?.marginRight,
      float: "right"
    },
    duration: props.duration || 3
  });

};